.landing-page-container {
    min-height: 100vh;
}

.hi-statement {
    font-family: 'Merriweather', sans-serif, serif;
    font-size: 2rem;
    color: orange;
}

.im-statement {
    font-size: 4rem;
    font-family: 'Merriweather', Times, serif;
    color: whitesmoke;
}

.i-specialize-statement {
    font-size: 1.5rem;
    font-family: Georgia, 'Times New Roman', Times, serif;
    color: rgb(156, 155, 155);
}

.btn {
    color: white;
    border-color: white;
}

.btn:hover {
    color: grey;
    border-color: grey;
}


@media (max-width: 600px) {
    .landing-page-container {
        padding-bottom: 10vh;
    }

    .hi-statement {
        font-size: 1.3rem;
    }

    .im-statement {
        font-size: 2.8rem;
    }
}
