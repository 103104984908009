.projects-page-container {
    min-height: 90vh;
    width: 100%;
    color: white;
}

.works-title {
    font-family: 'Times New Roman', Times, serif;
    font-size: 1.8rem;
    font-weight: 900;
}

.works-title:after {
    content: "";
    display: block;
    position: relative;
    top: -15px;
    width: 70%;
    height: 1px;
    margin-left: 100px;
    background-color: white;
}

.projects-container {
    height: 100%;
    width: 100%;
}

.project-container-container1 {
    height: 100%;
    width: 100%;
    height: 15rem;
    background-image: url('../../Assets/movingco-photo.png');
    background-size: cover;
}

.project-container-container1:hover {
    cursor: pointer;
    color: rgb(174, 174, 174);
}

.project-container-container2 {
    height: 100%;
    width: 100%;
    height: 15rem;
    background-image: url('../../Assets/redoit-photo.png');
    background-size: cover;
}

.project-container-container2:hover {
    cursor: pointer;
    color: rgb(174, 174, 174);
}

.project-container-container3 {
    height: 100%;
    width: 100%;
    height: 15rem;
    background-image: url('../../Assets/Blouson-photo.png');
    background-size: cover;
}

.project-container-container3:hover {
    cursor: pointer;
    color: rgb(174, 174, 174);
}

.opacity-container {
    height: 100%;
    width: 100%;
    opacity: 70%;
    border: solid 1px black;
    background-color: black;
    font-weight: 500;
}

.project-title {
    font-size: 1.2rem;
    font-weight: 600;
    color: white;
    font-family: 'Times New Roman', Times, serif;
}

.project-desc{
    font-size: 0.9rem;
    font-family: Georgia, 'Times New Roman', Times, serif;
}

.tech-used {
    font-family: 'Times New Roman', Times, serif;
    font-size: 1.1rem;
}

.tech {
    font-size: 0.8rem;
}

.devicon-github-original-wordmark {
    font-size: 2rem;
}

.btn {
    font-size: 0.8rem;
}

a:hover {
    cursor: pointer;
    color: rgb(174, 174, 174) !important;
}

.project-links {
    gap: 1rem;
}