.about-page-container {
    min-height: 90vh;
    width: 100%;
    color: whitesmoke;
}

.about-title {
    font-family: 'Times New Roman', Times, serif;
    font-size: 1.8rem;
    font-weight: 900;
}

.about-title:after {
    content: "";
    display: block;
    position: relative;
    top: -15px;
    width: 60%;
    height: 1px;
    margin-left: 140px;
    background-color: white;
}

.about-description {
    font-size: 1.3rem;
    font-family: 'Playfair', 'Times New Roman', Times, serif;
}

.img-container {
    width: 60%;
    border: solid 3px white;
}

.professional-img {
    width: 100%;
}

.text-and-img-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

@media (min-width: 700px) {
    .text-and-img-container {
        display: flex;
        flex-direction: row;
    }

    .about-description {
        width: 100%;
    }
}
