.navbar {
    width: 100%;
    margin: 0 auto;
    opacity: 100%;
    background-color: inherit;
    color: whitesmoke;
    transition: top 0.6s;
    background-color: rgb(22, 19, 21);
    opacity: 98%;
}

.fluid-container {
    width: 100%;
}

.brand {
    font-family: 'Playfair Display', serif;
    font-size: 1.8rem;
    font-weight: 600;
    color: inherit;
}

.brand:hover {
    color: grey;
}

.nav-link:hover {
    cursor: pointer;
    color: grey;
}

.nav-link {
    color: inherit;
    font-family: Georgia, 'Times New Roman', Times, serif;
    font-size: 1.4rem;
}

@media (min-width: 600px) {
    .navbar {
        width: 90%;
        margin: 0 auto;
    }
}