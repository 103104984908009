* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  width: 100%;
}

body {
  width: 100%;
  min-height: 100%;
  max-width: 1000px;
  margin: 0 auto;
  overflow-x: hidden;
  display: flex;
  
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgb(22, 19, 21);
}

main {
  padding: 0 30px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  color: inherit;
  text-decoration: inherit;
}

ul, li {
  margin: 0;
  padding: 0;
  text-indent: none;
  list-style-type: none;
}

button {
  border: none;
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;

  background: transparent;

  /* inherit font & color from ancestor */
  color: inherit;
  font: inherit;

  /* Normalize `line-height`. Cannot be changed from `normal` in Firefox 4+. */
  line-height: normal;

  /* Corrects font smoothing for webkit */
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;

  /* Corrects inability to style clickable `input` types in iOS */
  -webkit-appearance: none;
}

@media (min-width: 600px) {
  main {
      padding: 0 125px;
  }
}
