.contact-page-container {
    width: 100%;
    color: white;
}

.contact-title {
    font-family: 'Times New Roman', Times, serif;
    font-size: 1.8rem;
    font-weight: 900;
}

.contact-title:after {
    content: "";
    display: block;
    position: relative;
    top: -15px;
    width: 63%;
    height: 1px;
    margin-left: 110px;
    background-color: white;
}

input,
textarea {
    font-family: inherit;
    width: 100%;
    border: 0;
    border-bottom: .13em solid white;
    outline: 0;
    font-size: .8em;
    color: whitesmoke;
    padding: 7px 0;
    background: transparent;
}

.btn {
    width: 100%;
}

.success-message {
    color: white;
    font-size: 2rem;
    font-family: 'Times New Roman', Times, serif;
}



.submit-btn {
    text-align: center;
    width: 20%;
}